import { service } from '.';

export default {
  getShow: (query) => service.get(`/tests/show?${query}`),
  getSelectTest: (query) => service.get(`/tests/select_test?${query}`),
  getAll: (query) => service.get(`/tests/get?${query}`),
  getAllGroups: (query) => service.get(`/test_groups/?${query}`),
  getOneGroup: (id) => service.get(`/test_groups/${id}/`),
  addGroup: (data) => service.post('/test_groups/', data),
  updateGroup: (id, data) => service.patch(`/test_groups/${id}/`, data),
  getAllExams: (query) => service.get(`/test_exams/?${query}`),
  getOneExam: (id) => service.get(`/test_exams/${id}/`),
  addExam: (data) => service.post('/test_exams/', data),
  updateExam: (id, data) => service.put(`/test_exams/${id}/`, data),
  getAllScores: (query) => service.get(`/user_scores/?${query}`),
  getAllExamResults: (query) => service.get(`/test_results/?${query}`),
  getOneExamResult: (id) => service.get(`/test_results/${id}/`),
  addExamResult: (data) => service.post('/test_results/', data),
  getAllDownload: (query) => service.get(`/tests/?${query}`),
  getTestsDownload: (query, data) => service.post('/tests_download/', data),
  getOne: (query) => service.get(`/tests/one?${query}`),
  getForums: (query) => service.get(`/tests/forums?${query}`),
  addForum: (data) => service.post('/test_forums/', data),
  addFavorite: (data) => service.post('/test_favorites/', data),
  getFavorites: (query) => service.get(`/test_favorites/${query}`),
  getSelectOption: (query) => service.get(`/tests/select_option?${query}`),
  getOneTest: (id) => service.get(`/tests/${id}/`),
  create: (data) => service.post('/tests/', data),
  update: (id, data) => service.patch(`/tests/${id}/`, data),
  delete: (id) => service.delete(`/tests/${id}/`),
  getNotifications: (query) => service.get(`/test_messages/?${query}`),
  createNotification: (data) => service.post('/test_messages/', data),
  deleteNotification: (id) => service.delete(`/test_messages/${id}/`),

  getTestSelectUnit: (query) => service.get(`/test_selects/?${query}`),
  getOneTestSelectUnit: (id) => service.get(`/test_selects/${id}/`),
  createTestSelectUnit: (data) => service.post('/test_selects/', data),
  updateTestSelectUnit: (id, data) =>
    service.patch(`/test_selects/${id}/`, data),
  deleteTestSelectUnit: (id) => service.delete(`/testest_selectsts/${id}/`),

  getTestPayments: (query) => service.get(`/test_payment_logs/?${query}`),

  getCompetitionTest: (query) => service.get(`/test_competitions/?${query}`),
  getCompetitionScore: (query, data) =>
    service.post(`/test_competitions/?${query}`, data),
};
