import { React, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  colors,
  Box,
  IconButton,
  Card,
  Container,
  InputAdornment,
  SvgIcon,
  TextField,
  Typography,
  InputLabel,
} from '@mui/material';
import jwtDecode from 'jwt-decode';
import Grid from '@mui/material/Grid';
import { Filter as FilterIcon, Search as SearchIcon } from 'react-feather';
import { AnimatePresence } from 'framer-motion';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import AddIcon from '@mui/icons-material/Add';
import Loading from '../../../../components/Loading';
import { toolTips } from './helper';
import { useStyles } from './style';
import Table from '../../../../components/Table';
import { examsHeader } from '../../../../redux/modules/table/common';
import { headerMaker } from '../../../../components/Table/helper';
import { fetchData } from '../../../../redux/modules/exam/actions';
import { setTotals } from '../../../../redux/modules/store/actions';
// import FilterTools from '../../../../components/FilteredBody/Subcategory';
import AnimationDiv from '../../../../components/Animations/Transition';
import { useQuery } from '../../../useQuery';
import locations from '../../../../services/locations';
import ModalWindow from '../../../../components/Modals';
import ChangeStatusModal from '../../../../components/Modals/ConfirmStatus';
import exam from '../../../../services/exam';

export default ({ ...rest }) => {
  const classes = useStyles();
  const {
    query,
    search,
    status,
    setSearch,
    setStatus,
    handleOnTableChange,
    setLocationId,
  } = useQuery({ fetchData });

  const dispatch = useDispatch();
  const [isShow, setIsShow] = useState(false);
  const navigate = useNavigate();
  const [locationList, setLocationList] = useState([]);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const { data, total } = useSelector((state) => state.examReducer);
  localStorage.setItem('total_data', total);
  const language = useSelector((state) => state.langsReducer.language);
  const examItem = useSelector((state) => state.ordersReducer.item);
  const headers = useMemo(() => headerMaker(examsHeader), [examsHeader]);

  const addExam = () => {
    navigate('/app/exam/create');
  };

  const doubleClick = (id) => {
    navigate('/app/exam/create', { state: id });
  };

  const token = localStorage.getItem('token');
  const decodedToken = jwtDecode(token);

  const validationSchema = Yup.object().shape({
    location_id: Yup.number().required(t('required')),
  });

  const formik = useFormik({
    initialValues: {
      locations: 'all',
    },
    validationSchema,
  });

  const getStaffTitleByLocationId = (id) => {
    setLoading(true);
    formik.setFieldValue('locations', id);
    if (id === 'all') {
      const locationIds = locationList.map((item) => item.location_id);
      const query = `${locationIds.join('&locations=')}`;
      setLocationId(query);
    } else {
      setLocationId(id);
    }
    setLoading(false);
  };

  const loadingData = () => {
    dispatch(setTotals(total));
    setLoading(true);
    const query = `user_id=${decodedToken.user_id}`;
    locations
      .getAll(query)
      .then((res) => {
        setLocationList(res.results);

        const locationIds = res.results.map((item) => item.location_id);
        const query = `${locationIds.join('&locations=')}`;
        setLocationId(query);

        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        try {
          if (err.response.status === 401) {
            setTimeout(() => {
              loadingData();
            }, 5000);
          }
        } catch (error) {
          //
        }
      });
  };

  useEffect(() => {
    loadingData();
  }, [language]);

  const handleModal = () => {
    setModal(!modal);
  };

  const changeStatus = () => {
    setLoading(true);
    const data = new FormData();
    data.append('status', examItem.status === 1 ? 0 : 1);

    exam
      .changeStatus(examItem.exam_id, data)
      .then((resp) => {
        setModal(!modal);
        setLoading(false);
        setSearch(examItem.name);
        setSearch('');
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.response);
      });
  };

  return (
    <>
      <Helmet>
        <title> {t('exam')} </title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 1,
        }}
      >
        <Container maxWidth={false}>
          <ModalWindow
            title={t('change_status')}
            modal={modal}
            handleModal={handleModal}
            maxWidth="60%"
            body={
              <ChangeStatusModal
                handleModal={handleModal}
                changeStatus={changeStatus}
                yes={t('yes')}
                cancel={t('cancel')}
                text={t('ask_change_status')}
              />
            }
          />
          <Loading loading={loading} />
          <Typography
            variant="h2"
            color="textPrimary"
            gutterBottom
            mb={4}
            mt={2}
          >
            {t('exams')}
          </Typography>
          <Grid container spacing={1} my={2}>
            <Grid item xs={11} md={11} sm={11}>
              <div className={classes.navFilterSection}>
                <TextField
                  className={classes.search}
                  size="small"
                  fullWidth
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SvgIcon fontSize="small" color="action">
                          <SearchIcon />
                        </SvgIcon>
                      </InputAdornment>
                    ),
                  }}
                  label={t('search')}
                  variant="outlined"
                />
              </div>
            </Grid>
            <Grid item xs={1} md={1} sm={1}>
              <IconButton
                sx={{
                  backgroundColor: '#2A455F',
                  color: '#FFFFFF',
                  textTransform: 'none',
                }}
                variant="contained"
                onClick={addExam}
              >
                <AddIcon />
              </IconButton>
            </Grid>
          </Grid>
          <Grid item xs={12} md={12} sm={12}>
            <FormControl
              size="small"
              sx={{ width: '100%', backgroundColor: 'white' }}
            >
              <InputLabel id="locations">{t('location')}</InputLabel>
              <Select
                labelId="locations"
                label={t('location')}
                className={classes.input}
                fullWidth
                name="locations"
                value={formik.values.locations}
                onBlur={formik.handleBlur}
                onChange={(e) => getStaffTitleByLocationId(e.target.value)}
              >
                <MenuItem key="all" value="all">
                  {t('all')}
                </MenuItem>
                {locationList.map((item, index) => (
                  <MenuItem key={`${index + 1}`} value={item.location_id}>
                    {item.location_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          {/* <AnimatePresence className={classes.filters}>
            {isShow ? (
              <AnimationDiv>
                <FilterTools status={status} setStatus={setStatus} />
              </AnimationDiv>
            ) : null}
          </AnimatePresence> */}
          <Box sx={{ pt: 2 }}>
            <div className={classes.root}>
              <Card {...rest}>
                <Table
                  data={data}
                  headers={headers}
                  toolTips={toolTips}
                  onChange={handleOnTableChange}
                  modal={handleModal}
                  doubleClick={doubleClick}
                />
              </Card>
            </div>
          </Box>
        </Container>
      </Box>
    </>
  );
};
