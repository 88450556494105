/* eslint-disable no-prototype-builtins */
import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import {
  colors,
  Grid,
  Pagination,
  Stack,
  Container,
  Typography,
  CardMedia,
  Avatar,
  Autocomplete,
  CircularProgress,
  TextField,
  Divider,
  IconButton,
  Tooltip,
  Card,
  CardContent,
  Button,
  Badge,
  Snackbar,
} from '@mui/material';
import Alert from '@mui/material/Alert';
import SnackbarContent from '@mui/material/SnackbarContent';
import jwtDecode from 'jwt-decode';
import Moment from 'react-moment';
import { useTheme } from '@mui/material/styles';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import DeleteIcon from '@mui/icons-material/Delete';
import RemoveIcon from '@mui/icons-material/Remove';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import ForumIcon from '@mui/icons-material/Forum';
import useMediaQuery from '@mui/material/useMediaQuery';
import ModalWindow from '../../../../../components/Modals';
import Loading from '../../../../../components/Loading';
import testsApi from '../../../../../services/tests';
import ChangeStatusModal from '../../../../../components/Modals/ConfirmStatus';
import { useStyles } from './style';

export default function SelectList({
  tests,
  examId,
  examName,
  locationId,
  classId,
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const theme = useTheme();
  const classes = useStyles();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const [testList, setTestList] = useState(tests);

  const [selectTestList, setSelectTestList] = useState([]);

  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const [modal, setModal] = useState(false);

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  useEffect(() => {
    localStorage.setItem('savedTest', JSON.stringify(tests.blok));

    const arr1 = tests.blok.map((obj) => obj.test_keys);

    const arr2 = arr1.map((obj) => obj.tests);
    const arr3 = arr2.map((obj) => obj.map((obj1) => obj1.testId));
    const arr4 = arr3.flat(1);
    setSelectTestList(arr4);
  }, []);

  const selectTest = (item, id, status, index, index2) => {
    const arrr = testList;
    arrr.tests[index][index2].select = status;
    setTestList({ blok: arrr.blok, tests: arrr.tests });

    const a = JSON.parse(localStorage.getItem('savedTest'));
    if (status) {
      const b = a[index].test_keys.tests.filter((obj) => obj.testId !== id);
      a[index].test_keys.tests = b;
      localStorage.setItem('savedTest', JSON.stringify(a));
    } else {
      a[index].test_keys.tests.push(item);
      localStorage.setItem('savedTest', JSON.stringify(a));
      console.log(arrr);
    }
  };

  const selectOption = (id, index, index2, key) => {
    testList.blok[index].test_keys.tests[index2].key = key;
    setTestList({
      blok: testList.blok,
      tests: testList.tests,
    });
    const a = JSON.parse(localStorage.getItem('savedTest'));
    const prs = a.map((item) => {
      const pr = item.test_keys.tests.map((item1) => {
        if (item1.testId === id) {
          return { ...item1, key };
        }
        return item1;
      });
      return {
        ...item,
        test_keys: {
          max_test: item.test_keys.max_test,
          tests: pr,
        },
      };
    });

    localStorage.setItem('savedTest', JSON.stringify(prs));

    // localStorage.setItem(
    //   'savedTest',
    //   JSON.stringify(testList.blok)
    // );
  };

  const downloadTestShuffle = () => {
    const data = new FormData();
    data.append('shuffle', 'yes');
    data.append('locationId', locationId);
    data.append('classId', classId);
    data.append('testIds', JSON.stringify(tests.blok));
    data.append('exam_name', examName);
    data.append('exam_id', examId.exam_id);
    testsApi
      .getTestsDownload('', data)
      .then((res) => {
        if (res.status) {
          window.open(res.filename);
        } else {
          setSnackbarMessage(
            "Xatolik sodir bo'ldi. Ma'lumot uchun: +998 90 309 39 78"
          );
          setOpenSnackbar(true);
        }

        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const downloadTests = (shuffle) => {
    setLoading(true);

    if (shuffle === 'yes') {
      try {
        const c = examId.student_keys[0];
        setModal(true);
      } catch (error) {
        downloadTestShuffle();
      }
    } else {
      const data = new FormData();

      // const arr1 = tests.blok.map((obj) => obj.test_keys);
      // const arr2 = arr1.map((obj) => obj.tests);
      // const arr3 = arr2.map((obj) => obj.map((obj1) => obj1.testId));
      // console.log(arr3);
      data.append('shuffle', 'no');
      data.append('classId', classId);
      data.append('testIds', JSON.stringify(tests.blok));
      data.append('exam_name', examName);
      testsApi
        .getTestsDownload('', data)
        .then((res) => {
          if (res.status) {
            window.open(res.filename);
          } else {
            setSnackbarMessage(
              "Xatolik sodir bo'ldi. Ma'lumot uchun: +998 90 309 39 78"
            );
            setOpenSnackbar(true);
          }
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    }
  };

  return (
    <Container maxWidth={false}>
      <Loading loading={loading} />
      <ModalWindow
        title={t('Saqlash')}
        modal={modal}
        handleModal={() => {
          setModal(false);
          setLoading(false);
        }}
        body={
          <ChangeStatusModal
            handleModal={() => {
              setLoading(false);
              setModal(false);
            }}
            changeStatus={() => {
              downloadTestShuffle();
              setModal(false);
            }}
            yes={t('yes')}
            cancel={t('cancel')}
            text={t('Saqlash bajarilsinmi ?')}
          />
        }
      />
      <Stack spacing={2} sx={{ width: '100%' }}>
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
          message={snackbarMessage}
        >
          <Alert
            onClose={handleCloseSnackbar}
            severity="warning"
            sx={{ width: '100%' }}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Stack>
      <Grid container my={1}>
        <Typography variant="h2" color="textPrimary" gutterBottom mb={3} mt={2}>
          {t('Tanlangan testlar')}
        </Typography>
        {tests.tests.length > 0 ? (
          <Grid container spacing={1}>
            <Grid item xs={12} md={6} sm={12}>
              <Button
                sx={{
                  backgroundColor: '#2A455F',
                  color: '#FFFFFF',
                  textTransform: 'none',
                }}
                variant="contained"
                startIcon={<FileDownloadIcon />}
                onClick={() => {
                  downloadTests('no');
                }}
                // color="info"
              >
                {t('download')}
              </Button>
            </Grid>
            <Grid item xs={12} md={6} sm={12}>
              <Button
                sx={{
                  backgroundColor: '#2A455F',
                  color: '#FFFFFF',
                  textTransform: 'none',
                }}
                variant="contained"
                startIcon={<AutorenewIcon />}
                onClick={() => {
                  downloadTests('yes');
                }}
                // color="info"
              >
                {t('download_shuffle')}
              </Button>
            </Grid>
            {/* <Grid item xs={4} md={4} sm={4}>
              <Button
                sx={{
                  backgroundColor: '#2A455F',
                  color: '#FFFFFF',
                  textTransform: 'none',
                }}
                variant="contained"
                startIcon={<DeleteIcon />}
                onClick={() => {
                  handleModal();
                }}
                // color="info"
              >
                {t('clear')}
              </Button>
            </Grid> */}
          </Grid>
        ) : null}
        <Grid item xs={12} md={12} sm={12}>
          <Grid container my={1}>
            {tests.tests.length === 0 ? (
              <Typography
                my={1}
                variant="h4"
                align="left"
                color={colors.grey[700]}
              >
                {t('no_test_data')}
              </Typography>
            ) : null}
            <Grid item xs={12} md={12} sm={12}>
              {testList.tests.map((items, index) => (
                <Card
                  key={`s${index + 1}`}
                  sx={{
                    marginRight: 1,
                    marginBottom: 1,
                    backgroundColor: '#edf2f2',
                  }}
                >
                  <CardContent>
                    <Grid item xs={12} md={12} sm={12}>
                      <Typography my={1} variant="h5" color={colors.grey[700]}>
                        &nbsp; &nbsp; {t('Blok') + ': ' + (index + 1)}; &nbsp;
                        &nbsp;
                        {testList.blok[index].block_name.name.uz}; &nbsp; &nbsp;
                        Soni:{' '}
                        {
                          testList.tests[index].filter((obj) => !obj.select)
                            .length
                        }
                      </Typography>
                      <Divider />
                      <br />
                    </Grid>
                    <Grid container my={1}>
                      {items.map((item, i) => (
                        <Grid key={`${i + 1}`} item xs={12} md={6} sm={12}>
                          <Card sx={{ marginRight: 1, marginBottom: 1 }}>
                            <Tooltip
                              title={t('selected')}
                              placement="top"
                              arrow
                            >
                              <IconButton
                                variant="filled"
                                onClick={() => {
                                  selectTest(
                                    testList.blok[index].test_keys.tests[i],
                                    item.tests[0].pk,
                                    !item.select,
                                    index,
                                    i
                                  );
                                }}
                              >
                                {item.select ? (
                                  <CheckCircleIcon color="success" />
                                ) : (
                                  <HighlightOffIcon color="error" />
                                )}
                              </IconButton>
                            </Tooltip>

                            <Typography variant="h5" color={colors.grey[700]}>
                              &nbsp; &nbsp; {t('Mavzu') + ': ' + item.topic.uz}
                            </Typography>
                            <CardContent>
                              <div
                                // eslint-disable-next-line react/no-danger
                                dangerouslySetInnerHTML={{
                                  __html: item.tests[0].fields.question.replace(
                                    'numberNN',
                                    i + 1 + '. '
                                  ),
                                }}
                              />
                              <Divider size="medium" color="primary" />
                              <div>
                                <Button
                                  fullWidth
                                  disableRipple
                                  sx={{
                                    marginTop: 1,
                                    marginBottom: 1,
                                    textTransform: 'none',
                                    justifyContent: 'flex-start',
                                    paddingRight: 1,
                                  }}
                                  variant={
                                    testList.blok[index].test_keys.tests[i]
                                      .testId === item.tests[0].pk &&
                                    testList.blok[index].test_keys.tests[i]
                                      .key === 1
                                      ? 'contained'
                                      : 'outlined'
                                  }
                                  color={
                                    testList.blok[index].test_keys.tests[i]
                                      .testId === item.tests[0].pk &&
                                    testList.blok[index].test_keys.tests[i]
                                      .key === 1
                                      ? 'primary'
                                      : 'warning'
                                  }
                                  startIcon={<Avatar>A</Avatar>}
                                  onClick={() => {
                                    selectOption(item.tests[0].pk, index, i, 1);
                                  }}
                                >
                                  <div
                                    style={{ paddingTop: 10 }}
                                    // eslint-disable-next-line react/no-danger
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        item.tests[0].fields.option_a.replace(
                                          'optionA',
                                          'A). '
                                        ),
                                    }}
                                  />
                                </Button>
                              </div>

                              <Divider size="medium" color="primary" />
                              <div>
                                <Button
                                  fullWidth
                                  disableRipple
                                  sx={{
                                    marginTop: 1,
                                    marginBottom: 1,
                                    textTransform: 'none',
                                    justifyContent: 'flex-start',
                                  }}
                                  variant={
                                    testList.blok[index].test_keys.tests[i]
                                      .testId === item.tests[0].pk &&
                                    testList.blok[index].test_keys.tests[i]
                                      .key === 2
                                      ? 'contained'
                                      : 'outlined'
                                  }
                                  color={
                                    testList.blok[index].test_keys.tests[i]
                                      .testId === item.tests[0].pk &&
                                    testList.blok[index].test_keys.tests[i]
                                      .key === 2
                                      ? 'primary'
                                      : 'warning'
                                  }
                                  startIcon={<Avatar>B</Avatar>}
                                  onClick={() => {
                                    selectOption(item.tests[0].pk, index, i, 2);
                                  }}
                                >
                                  <div
                                    style={{ paddingTop: 10 }}
                                    // eslint-disable-next-line react/no-danger
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        item.tests[0].fields.option_b.replace(
                                          'optionB',
                                          'B). '
                                        ),
                                    }}
                                  />
                                </Button>
                              </div>
                              <Divider size="medium" color="primary" />
                              <div>
                                <Button
                                  fullWidth
                                  disableRipple
                                  sx={{
                                    marginTop: 1,
                                    marginBottom: 1,
                                    textTransform: 'none',
                                    justifyContent: 'flex-start',
                                  }}
                                  variant={
                                    testList.blok[index].test_keys.tests[i]
                                      .testId === item.tests[0].pk &&
                                    testList.blok[index].test_keys.tests[i]
                                      .key === 3
                                      ? 'contained'
                                      : 'outlined'
                                  }
                                  color={
                                    testList.blok[index].test_keys.tests[i]
                                      .testId === item.tests[0].pk &&
                                    testList.blok[index].test_keys.tests[i]
                                      .key === 3
                                      ? 'primary'
                                      : 'warning'
                                  }
                                  startIcon={<Avatar>C</Avatar>}
                                  onClick={() => {
                                    selectOption(item.tests[0].pk, index, i, 3);
                                  }}
                                >
                                  <div
                                    style={{ paddingTop: 10 }}
                                    // eslint-disable-next-line react/no-danger
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        item.tests[0].fields.option_c.replace(
                                          'optionC',
                                          'C). '
                                        ),
                                    }}
                                  />
                                </Button>
                              </div>
                              <Divider size="medium" color="primary" />
                              <div>
                                <Button
                                  fullWidth
                                  disableRipple
                                  sx={{
                                    marginTop: 1,
                                    marginBottom: 1,
                                    textTransform: 'none',
                                    justifyContent: 'flex-start',
                                  }}
                                  variant={
                                    testList.blok[index].test_keys.tests[i]
                                      .testId === item.tests[0].pk &&
                                    testList.blok[index].test_keys.tests[i]
                                      .key === 4
                                      ? 'contained'
                                      : 'outlined'
                                  }
                                  color={
                                    testList.blok[index].test_keys.tests[i]
                                      .testId === item.tests[0].pk &&
                                    testList.blok[index].test_keys.tests[i]
                                      .key === 4
                                      ? 'primary'
                                      : 'warning'
                                  }
                                  startIcon={<Avatar>D</Avatar>}
                                  onClick={() => {
                                    selectOption(item.tests[0].pk, index, i, 4);
                                  }}
                                >
                                  <div
                                    style={{ paddingTop: 10 }}
                                    // eslint-disable-next-line react/no-danger
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        item.tests[0].fields.option_d.replace(
                                          'optionD',
                                          'D). '
                                        ),
                                    }}
                                  />
                                </Button>
                              </div>
                            </CardContent>
                          </Card>
                        </Grid>
                      ))}
                    </Grid>
                  </CardContent>
                </Card>
              ))}
            </Grid>
          </Grid>
        </Grid>
        <br />
      </Grid>
    </Container>
  );
}
