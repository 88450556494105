import { service } from '.';

export default {
  getAll: (query) => service.get(`/exam/?search=&${query}`),
  getOne: (id) => service.get(`/exam/${id.exam_id}/`),
  getByLocationId: (query) => service.get(`/exam/?locations=${query}`),
  create: (data) => service.post('/exam/', data),
  update: (id, data) => service.patch(`/exam/${id.exam_id}/`, data),
  changeStatus: (id, data) => service.patch(`/exam/status/${id}/`, data),
};
