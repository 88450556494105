export function dataSelector(dataResponse, page, size) {
  const timeConvert = (time) => {
    if (time !== null) {
      const sp1 = time.split('T');
      const sp2 = sp1[1].split(':');
      const strTime = sp1[0] + ' ' + sp2[0] + ':' + sp2[1];
      return strTime;
    }
    return time;
  };
  const filtered = dataResponse.results.map((item, i) => ({
    index: i + 1 + page * size,
    id: item,
    locations: item.locations,
    name: item.name,
    employee: item.employee,
    class: item.classes.class_name,
    status: item.status,
    created_time: timeConvert(item.created_at),
  }));
  return { total: dataResponse.count, data: filtered };
}
