import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import {
  Box,
  colors,
  CircularProgress,
  Stack,
  FormHelperText,
  Snackbar,
  FormControlLabel,
  InputLabel,
} from '@mui/material';
import TimePicker from '@mui/lab/TimePicker';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import jwtDecode from 'jwt-decode';
import { useTheme } from '@mui/material/styles';
import { ToastContainer } from 'react-toastify';
import { useLocation } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import lessons from '../../../services/lessons';
import employees from '../../../services/employees';
import { Alert, IOSSwitch } from '../../../components/GlobalStyles';
import { useStyles } from './style';
import { localeMap } from '../../../constants';

export default ({
  load,
  onClose,
  locationId,
  classId,
  scienceList,
  lessonData,
  day,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const language = useSelector((state) => state.langsReducer.language);
  const { state } = useLocation();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { t } = useTranslation();
  const token = localStorage.getItem('token');
  const decodedToken = jwtDecode(token);
  const [teacherLists, setTeacherLists] = useState([]);

  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [open, setOpen] = useState(false);
  const [repeat, setRepeat] = useState(false);

  function addHoursToDate(date, hours) {
    return new Date(new Date(date).setHours(date.getHours() + hours));
  }

  const validationSchema = Yup.object().shape({
    locations: Yup.string().required(t('required')),
    classes: Yup.string().required(t('required')),
    teacher: Yup.string().required(t('required')),
    science: Yup.string().required(t('required')),
  });

  const formik = useFormik({
    initialValues: {
      locations: locationId,
      classes: classId,
      teacher: '',
      science: '',
      start: new Date(),
      end: addHoursToDate(new Date(), 1),
      additional: '',
    },
    validationSchema,
    onSubmit: (values) => {
      console.log(lessonData);
      setIsSubmitting(true);
      const start = addHoursToDate(values.start, 0);
      const end = addHoursToDate(values.end, 0);

      if (lessonData.length === 0) {
        const lesson = JSON.stringify([
          {
            science: { science_id: values.science },
            teacher: { employee_id: values.teacher },
            start: start.toISOString(),
            end: end.toISOString(),
            startText:
              (new Date(values.start).getHours() < 10
                ? '0' + new Date(values.start).getHours()
                : new Date(values.start).getHours()) +
              ':' +
              (new Date(values.start).getMinutes() < 10
                ? '0' + new Date(values.start).getMinutes()
                : new Date(values.start).getMinutes()),
            endText:
              (new Date(values.end).getHours() < 10
                ? '0' + new Date(values.end).getHours()
                : new Date(values.end).getHours()) +
              ':' +
              (new Date(values.end).getMinutes() < 10
                ? '0' + new Date(values.end).getMinutes()
                : new Date(values.end).getMinutes()),
            additional: values.additional,
          },
        ]);
        const empty = JSON.stringify([]);
        const data = new FormData();
        data.append('classes', classId);
        data.append('locations', locationId);
        if (repeat) {
          data.append('monday', lesson);
          data.append('tuesday', lesson);
          data.append('wednesday', lesson);
          data.append('thursday', lesson);
          data.append('friday', lesson);
          data.append('saturday', lesson);
          data.append('sunday', empty);
        } else {
          data.append('monday', day === 'monday' ? lesson : empty);
          data.append('tuesday', day === 'tuesday' ? lesson : empty);
          data.append('wednesday', day === 'wednesday' ? lesson : empty);
          data.append('thursday', day === 'thursday' ? lesson : empty);
          data.append('friday', day === 'friday' ? lesson : empty);
          data.append('saturday', day === 'saturday' ? lesson : empty);
          data.append('sunday', day === 'sunday' ? lesson : empty);
        }
        data.append('status', 1);
        lessons
          .create(data)
          .then((resp) => {
            load();
            onClose();
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        let d = 0;
        for (let i = 0; i < lessonData[0][day].length; i += 1) {
          const item = lessonData[0][day][i];
          const st =
            (new Date(item.start).getHours() < 10
              ? '0' + new Date(item.start).getHours()
              : new Date(item.start).getHours()) +
            ':' +
            (new Date(item.start).getMinutes() < 10
              ? '0' + new Date(item.start).getMinutes()
              : new Date(item.start).getMinutes()) +
            ' - ' +
            (new Date(item.end).getHours() < 10
              ? '0' + new Date(item.end).getHours()
              : new Date(item.end).getHours()) +
            ':' +
            (new Date(item.end).getMinutes() < 10
              ? '0' + new Date(item.end).getMinutes()
              : new Date(item.end).getMinutes());

          const cr =
            (new Date(values.start).getHours() < 10
              ? '0' + new Date(values.start).getHours()
              : new Date(values.start).getHours()) +
            ':' +
            (new Date(values.start).getMinutes() < 10
              ? '0' + new Date(values.start).getMinutes()
              : new Date(values.start).getMinutes()) +
            ' - ' +
            (new Date(values.end).getHours() < 10
              ? '0' + new Date(values.end).getHours()
              : new Date(values.end).getHours()) +
            ':' +
            (new Date(values.end).getMinutes() < 10
              ? '0' + new Date(values.end).getMinutes()
              : new Date(values.end).getMinutes());
          if (st === cr) {
            d = 1;
          }
        }

        if (new Date(values.end) <= new Date(values.start)) {
          setSnackbarMessage(t('wrong_lesson_time'));
          setOpen(true);
          setIsSubmitting(false);
        } else if (d !== 0) {
          setSnackbarMessage(t('already_exists_lesson'));
          setOpen(true);
          setIsSubmitting(false);
        } else {
          const lessonItem = {
            science: { science_id: values.science },
            teacher: { employee_id: values.teacher },
            start: start.toISOString(),
            end: end.toISOString(),
            startText:
              (new Date(values.start).getHours() < 10
                ? '0' + new Date(values.start).getHours()
                : new Date(values.start).getHours()) +
              ':' +
              (new Date(values.start).getMinutes() < 10
                ? '0' + new Date(values.start).getMinutes()
                : new Date(values.start).getMinutes()),
            endText:
              (new Date(values.end).getHours() < 10
                ? '0' + new Date(values.end).getHours()
                : new Date(values.end).getHours()) +
              ':' +
              (new Date(values.end).getMinutes() < 10
                ? '0' + new Date(values.end).getMinutes()
                : new Date(values.end).getMinutes()),
            additional: values.additional,
          };
          if (repeat) {
            lessonData[0].monday.push(lessonItem);
            lessonData[0].tuesday.push(lessonItem);
            lessonData[0].wednesday.push(lessonItem);
            lessonData[0].thursday.push(lessonItem);
            lessonData[0].friday.push(lessonItem);
            lessonData[0].saturday.push(lessonItem);
            // lessonData[0].sunday.push(lessonItem);
          } else {
            lessonData[0][day].push(lessonItem);
          }

          lessons
            .update(lessonData[0].lesson_id, lessonData[0])
            .then((resp) => {
              load();
              onClose();
            })
            .catch((err) => {
              console.log(err);
            });
        }
      }
    },
  });

  const handleRepeat = () => {
    setRepeat(!repeat);
  };

  useEffect(() => {}, []);

  const renderButton = (state, isSubmitting) => {
    if (state) {
      if (isSubmitting) {
        return t('updating');
      }
      return t('update');
    }
    if (isSubmitting) {
      return t('submitting');
    }
    return t('submit');
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  return (
    <Box
      sx={{
        backgroundColor: '#E5E8EC',
        // minHeight: '100vh',
        pt: 2,
      }}
    >
      <Container>
        <Stack spacing={2} sx={{ width: '100%' }}>
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={open}
            autoHideDuration={6000}
            onClose={handleClose}
          >
            <Alert onClose={handleClose} severity="info" sx={{ width: '100%' }}>
              {snackbarMessage}
            </Alert>
          </Snackbar>
        </Stack>
        <form autoComplete="off" onSubmit={formik.handleSubmit}>
          <Grid container>
            <Grid item xs={12} md={12} sm={12}>
              <FormControl
                size="small"
                sx={{
                  width: '100%',
                  backgroundColor: 'white',
                }}
              >
                <InputLabel id="science">{t('science')}</InputLabel>
                <Select
                  labelId="science"
                  label={t('science')}
                  className={classes.input}
                  fullWidth
                  value={formik.values.science}
                  name="science"
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.science && Boolean(formik.errors.science)
                  }
                  onChange={(e) => {
                    formik.setFieldValue('science', e.target.value);
                    const query = `locations=${locationId}&sciences=${e.target.value}`;
                    employees
                      .getAll(query + '&is_coach=1')
                      .then((resp) => {
                        console.log(resp.results);
                        setTeacherLists(resp.results);
                      })
                      .catch((err) => {});
                  }}
                >
                  {scienceList.map((item, i) => (
                    <MenuItem key={`${i + 1}`} value={item.science_id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormHelperText
                sx={{
                  color: colors.red[700],
                }}
              >
                {formik.errors.science &&
                  formik.touched.science &&
                  formik.errors.science}
              </FormHelperText>
            </Grid>

            <Grid item xs={12} md={12} sm={12} my={1}>
              <FormControl
                size="small"
                sx={{
                  width: '100%',
                  backgroundColor: 'white',
                }}
              >
                <InputLabel id="teacher">{t('teacher')}</InputLabel>
                <Select
                  labelId="teacher"
                  label={t('teacher')}
                  className={classes.input}
                  fullWidth
                  value={formik.values.teacher}
                  name="teacher"
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.teacher && Boolean(formik.errors.teacher)
                  }
                  onChange={(e) => {
                    console.log(e.target.value);
                    formik.setFieldValue('teacher', e.target.value.employee_id);
                  }}
                >
                  {teacherLists.map((item, i) => (
                    <MenuItem key={`${i + 1}`} value={item}>
                      {item.user_id.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormHelperText
                sx={{
                  color: colors.red[700],
                }}
              >
                {formik.errors.teacher &&
                  formik.touched.teacher &&
                  formik.errors.teacher}
              </FormHelperText>
            </Grid>

            <Grid item md={12} xs={12} sm={12} my={1}>
              <Grid item xs={12} md={12} sm={12}>
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  locale={localeMap[language]}
                >
                  <Stack spacing={3} className={classes.input}>
                    <TimePicker
                      label={t('begin_hour')}
                      clearable
                      ampm={false}
                      value={formik.values.start}
                      onChange={(e) => formik.setFieldValue('start', e)}
                      error={
                        formik.touched.start && Boolean(formik.errors.start)
                      }
                      helperText={formik.touched.start && formik.errors.start}
                      renderInput={(params) => (
                        <TextField size="small" {...params} />
                      )}
                    />
                  </Stack>
                </LocalizationProvider>
              </Grid>
            </Grid>

            <Grid item md={12} xs={12} sm={12} my={1}>
              <Grid item xs={12} md={12} sm={12}>
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  locale={localeMap[language]}
                >
                  <Stack spacing={3} className={classes.input}>
                    <TimePicker
                      label={t('end_hour')}
                      clearable
                      ampm={false}
                      minTime={new Date(formik.values.start)}
                      value={formik.values.end}
                      onChange={(e) => formik.setFieldValue('end', e)}
                      error={formik.touched.end && Boolean(formik.errors.end)}
                      helperText={formik.touched.end && formik.errors.end}
                      renderInput={(params) => (
                        <TextField size="small" {...params} />
                      )}
                    />
                  </Stack>
                </LocalizationProvider>
              </Grid>
            </Grid>

            <Grid item xs={12} md={12} sm={12} my={1}>
              <div className={classes.topBtns}>
                <FormControlLabel
                  value="start"
                  control={
                    <IOSSwitch
                      sx={{ m: 1 }}
                      checked={repeat}
                      value={repeat}
                      onChange={handleRepeat}
                    />
                  }
                  label={t('repeat') + '?'}
                  labelPlacement="start"
                />
              </div>
            </Grid>

            <Grid item xl={12} md={12} xs={12}>
              <TextField
                label={t('additional')}
                name="additional"
                fullWidth
                multiline
                rows={4}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                variant="outlined"
                placeholder={t('additional')}
                value={formik.values.additional}
                // error={formik.touched.notes && Boolean(formik.errors.notes)}
                InputProps={{
                  style: {
                    backgroundColor: theme.palette.background.paper,
                  },
                }}
                InputLabelProps={{
                  style: { color: 'black' },
                }}
                // helperText={formik.touched.notes && formik.errors.notes}
              />
            </Grid>

            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                gap: 2,
                py: 2,
              }}
            >
              <Button
                sx={{
                  backgroundColor: '#CFE0F0',
                  color: '#161616',
                  textTransform: 'none',
                }}
                variant="contained"
                onClick={onClose}
              >
                {t('cancel')}
              </Button>
              <Button
                sx={{
                  backgroundColor: '#2A455F',
                  color: colors.white,
                  textTransform: 'none',
                }}
                name="add"
                variant="contained"
                type="submit"
                color="primary"
                onClick={() => {}}
                startIcon={
                  isSubmitting ? <CircularProgress size="0.9rem" /> : undefined
                }
              >
                {renderButton(state, isSubmitting)}
              </Button>
            </Box>
          </Grid>
        </form>
        <ToastContainer />
      </Container>
    </Box>
  );
};
