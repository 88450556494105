import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Box,
  Button,
  IconButton,
  colors,
  Grid,
  TextField,
  Typography,
  Card,
  CardContent,
  Divider,
  CircularProgress,
  Container,
  Select,
  MenuItem,
  InputLabel,
  Autocomplete,
  Avatar,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  FormHelperText,
  FormControlLabel,
} from '@mui/material';
import Pagination from '@mui/material/Pagination';
import jwtDecode from 'jwt-decode';
import { useFormik } from 'formik';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import { styled, useTheme } from '@mui/material/styles';
import { toast, ToastContainer } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import EditIcon from '@mui/icons-material/Edit';
import Checkbox from '@mui/material/Checkbox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import GroupIcon from '@mui/icons-material/Group';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Tooltip from '@mui/material/Tooltip';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import Loading from '../../../../components/Loading';
import exam from '../../../../services/exam';
import locations from '../../../../services/locations';
import classesApi from '../../../../services/class';
import staffs from '../../../../services/employees';
import topicTest from '../../../../services/topic_tests';
import testsApi from '../../../../services/tests';
import scienceTestApi from '../../../../services/science_tests';
import { useStyles } from './style';
import { IOSSwitch, Alert } from '../../../../components/GlobalStyles';
import { omrType } from '../../../../constants';
import ModalWindow from '../../../../components/Modals';
import ChangeStatusModal from '../../../../components/Modals/ConfirmStatus';
import SelectList from '../Modals/SelectsList';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export default () => {
  const classes = useStyles();
  const theme = useTheme();
  const navigate = useNavigate();
  const language = useSelector((state) => state.langsReducer.language);
  const { state } = useLocation();

  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const [locationSchema, setLocationSchema] = useState([]);
  const [employeeSchema, setEmployeeSchema] = useState([]);
  const [classesSchema, setClassesSchema] = useState([]);

  const [testList, setTestList] = useState([]);
  const [topicList, setTopicList] = useState([]);
  const [topicName, setTopicName] = useState();

  const [selectTestList, setSelectTestList] = useState([]);
  const [selectTestList3, setSelectTestList3] = useState([]);

  const [selectTestModal, setSelectTestModal] = useState([]);

  const [countPages, setCountPages] = useState(0);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const [status, setStatus] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [open, setOpen] = useState(false);

  const [modal, setModal] = useState(false);
  const [modalSaved, setModalSaved] = useState(false);

  const [expanded, setExpanded] = useState(false);

  const [scienceList, setScienceList] = useState([]);

  const [showTestList, setShowTestList] = useState([]);
  const [showTestIndex, setShowTestIndex] = useState(0);

  const token = localStorage.getItem('token');
  const decodedToken = jwtDecode(token);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const validationSchema = Yup.object().shape({
    locations: Yup.string().required(t('required')),
    employee: Yup.string().required(t('required')),
    classes: Yup.string().required(t('required')),
    name: Yup.string().required(t('required')),
    omr_type: Yup.string().required(t('required')),
  });

  const formik = useFormik({
    initialValues: {
      locations: '',
      name: '',
      employee: '',
      classes: '',
      omr_type: '',
      block_ball: [],
      topic_name: '',
      notes: '',
      student_keys: [],
    },
    validationSchema,
    onSubmit: (values) => {
      setLoading(true);
      const data = new FormData();
      // const omr = 'select_test_' + values.omr_type.replace('-', '_');
      // const arr = JSON.parse(localStorage.getItem(omr));

      data.append('locations', values.locations);
      data.append('employee', values.employee);
      data.append('classes', values.classes);
      data.append('name', values.name);
      data.append('omr_type', values.omr_type);
      data.append('block_ball', JSON.stringify(values.block_ball));
      data.append('student_keys', JSON.stringify(values.student_keys));
      data.append('notes', values.notes);
      data.append('status', status ? 1 : 0);

      if (state) {
        exam
          .update(state, data)
          .then((res) => {
            setLoading(false);
            navigate('/app/exam');
          })
          .catch((err) => {
            setLoading(false);
            console.log(err);
            formik.setSubmitting(false);
            setSnackbarMessage(t(err.response.data.error));
            setOpen(true);
          });
      } else {
        exam
          .create(data)
          .then((res) => {
            setLoading(false);
            navigate('/app/exam');
          })
          .catch((err) => {
            setLoading(false);
            console.log(err.response);
            formik.setSubmitting(false);
            setSnackbarMessage(t(err.response.data.error));
            setOpen(true);
          });
      }
    },
  });

  const selectTest = (item, status, index) => {
    const omr = 'select_test_' + formik.values.omr_type.replace('-', '_');
    if (localStorage.getItem(omr)) {
      const arr = JSON.parse(localStorage.getItem(omr));
      if (arr[index].tests.length >= arr[index].max_test) {
        setSnackbarMessage(
          t(
            'Tanlash limit ' +
              arr[index].max_test +
              'ta. Tanlov limitdan oshib ketdi'
          )
        );
        setOpen(true);
      } else if (status === 1) {
        arr[index].tests.push({ testId: item.test_id, key: 0 });
        localStorage.setItem(omr, JSON.stringify(arr));
      } else {
        localStorage.setItem(
          omr,
          JSON.stringify(
            arr[index].tests.filter((value) => value.testId !== item.test_id)
          )
        );
      }
    } else {
      localStorage.setItem(omr, JSON.stringify([{ max_test: 30, tests: [] }]));
    }
    formik.values.block_ball[index].test_keys = JSON.parse(
      localStorage.getItem(omr)
    )[index];

    setSelectTestList(JSON.parse(localStorage.getItem(omr))[index].tests);
  };

  const selectOption = (item, status, scienceId, key, index) => {
    const omr = 'select_test_' + formik.values.omr_type.replace('-', '_');
    if (localStorage.getItem(omr)) {
      const arr = JSON.parse(localStorage.getItem(omr));
      // if (arr[index].tests.length > arr[index].max_test) {
      //   setSnackbarMessage(
      //     t('Tanlash limit 30 ta. Tanlov limitdan oshib ketdi')
      //   );
      //   setOpen(true);
      //   return;
      // }
      if (status === 1) {
        setSnackbarMessage(t('Iltimos, avval testni tanlang'));
        setOpen(true);
        return;
      }
      if (status === 0) {
        const objIndex = arr[index].tests.findIndex(
          (obj) => obj.testId === item.test_id
        );
        const newArr = arr[index].tests.map((obj) =>
          obj.testId === item.test_id ? { ...obj, key } : obj
        )[objIndex];
        arr[index].tests[objIndex] = newArr;
        console.log(arr);

        localStorage.setItem(omr, JSON.stringify(arr));
      }
    } else {
      setSnackbarMessage(t('Iltimos, avval testni tanlang'));
      setOpen(true);
    }
    formik.values.block_ball[index].test_keys = JSON.parse(
      localStorage.getItem(omr)
    )[index];

    setSelectTestList(JSON.parse(localStorage.getItem(omr))[index].tests);
  };

  const getByLocationId = (id) => {
    setLoading(true);
    staffs
      .getAll(id + '&is_coach=1')
      .then((resp) => {
        if (resp.results.length === 0) {
          setSnackbarMessage('');
          setOpen(true);
        } else {
          setEmployeeSchema(
            resp.results.map((item) => ({
              name: item.user_id.name,
              id: item.employee_id,
            }))
          );
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });

    classesApi
      .getAll(id)
      .then((ress) => {
        if (ress.results.length === 0) {
          setSnackbarMessage('');
          setOpen(true);
        } else {
          setClassesSchema(
            ress.results.map((item) => ({
              name: item.class_name,
              id: item.class_id,
            }))
          );
        }
      })
      .catch((err) => console.log(err));
    setLoading(false);
  };

  const getTestsByTopicId = (currentPage, id) => {
    // setTestList([]);
    localStorage.setItem('topic_id', id);
    setLoading(true);
    const query =
      `page=${currentPage}&page_size=${pageSize}&status=1&topic=` + id;
    testsApi
      .getAll(query)
      .then((res) => {
        setCountPages(res.count_pages);
        setTestList(res.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const getTopicByScienceId = (id) => {
    setTestList([]);
    setTopicList([]);
    formik.setFieldValue('topic_name', '');

    setLoading(false);
    const query = 'science_id=' + id;
    topicTest
      .getAll(query)
      .then((res) => {
        setTopicList(res);
        if (res.length > 0) {
          formik.setFieldValue('topic_name', res[0].name.uz);

          getTestsByTopicId(1, res[0].topic_test_id);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
    setLoading(false);
  };

  const handleChange = (event, value) => {
    setPage(value);
    const tId = localStorage.getItem('topic_id');
    getTestsByTopicId(value, tId);
  };

  const loadingData = () => {
    setLoading(true);
    const query = `user_id=${decodedToken.user_id}`;
    locations
      .getAll(query)
      .then((res) => {
        setLocationSchema(
          res.results.map((item) => ({
            name: item.location_name,
            id: item.location_id,
          }))
        );

        if (state) {
          exam
            .getOne(state)
            .then((resp) => {
              const query = `status=1&locations=${resp.locations.location_id}`;
              staffs
                .getAll(query + '&is_coach=1')
                .then((respS) => {
                  if (respS.results.length === 0) {
                    setSnackbarMessage('');
                    setOpen(true);
                  } else {
                    setEmployeeSchema(
                      respS.results.map((item) => ({
                        name: item.user_id.name,
                        id: item.employee_id,
                      }))
                    );
                  }
                  classesApi
                    .getAll(query)
                    .then((ressR) => {
                      if (ressR.results.length === 0) {
                        setSnackbarMessage('');
                        setOpen(true);
                      } else {
                        setClassesSchema(
                          ressR.results.map((item) => ({
                            name: item.class_name,
                            id: item.class_id,
                          }))
                        );
                      }
                      formik.setValues({
                        ...formik.values,
                        locations: resp.locations.location_id,
                        name: resp.name,
                        notes: resp.notes,
                        employee: resp.employee.employee_id,
                        classes: resp.classes.class_id,
                        omr_type: resp.omr_type,
                        block_ball: resp.block_ball,
                        student_keys: resp.student_keys,
                      });
                    })
                    .catch((err) => console.log(err));
                })
                .catch((err) => console.log(err));

              const omr = 'select_test_' + resp.omr_type.replace('-', '_');
              const arr = [];
              resp.block_ball.forEach((element) => {
                if (element.test_keys) {
                  arr.push(element.test_keys);
                } else {
                  arr.push({ tests: [], max_test: 10 });
                }
              });
              localStorage.setItem(omr, JSON.stringify(arr));

              if (resp.status === 1) {
                setStatus(true);
              } else {
                setStatus(false);
              }
            })
            .catch((err) => console.log(err));
        }
        scienceTestApi
          .getAll('page_size=100')
          .then((res) => {
            setScienceList(res);
            setLoading(false);
          })
          .catch((err) => {
            setLoading(false);
            console.log(err);
          });
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        try {
          if (err.response.status === 401) {
            setTimeout(() => {
              loadingData();
            }, 5000);
          }
        } catch (error) {
          //
        }
      });
  };

  const handleChangeExpanded = (panel, item, index) => (event, isExpanded) => {
    getTopicByScienceId(item.block_name.science_id);
    const omr = 'select_test_' + formik.values.omr_type.replace('-', '_');
    if (localStorage.getItem(omr)) {
      setSelectTestList(JSON.parse(localStorage.getItem(omr))[index].tests);
    } else {
      setSelectTestList([]);
    }

    setExpanded(isExpanded ? panel : false);
  };

  const getTestShow = (mainArr, arr, i) => {
    const arr3 = arr[i];
    const arr4 = arr3.flat(1);
    if (arr4.length > 0) {
      const queryTest = `${arr4.join('&testIds=')}`;
      const query = 'testIds=' + queryTest;
      testsApi
        .getShow(query)
        .then((res) => {
          mainArr.tests.push(res);

          if (arr.length - 1 === i) {
            setLoading(false);
            setShowTestList(mainArr);
            setModal(true);
          } else {
            setShowTestIndex(showTestIndex + 1);
            getTestShow(mainArr, arr, i + 1);
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    } else {
      mainArr.tests.push([]);
    }
  };

  useEffect(() => {
    loadingData();
  }, [state]);

  const handleStatus = () => {
    setStatus(!status);
  };

  const renderButton = (state, isSubmitting) => {
    if (state) {
      if (isSubmitting) {
        return t('updating');
      }
      return t('update');
    }
    if (isSubmitting) {
      return t('submitting');
    }
    return t('submit');
  };

  return (
    <Box
      sx={{
        backgroundColor: '#E5E8EC',
        p: 2,
        minHeight: '100%',
      }}
    >
      <Container>
        <ModalWindow
          title={t('Saqlash')}
          modal={modalSaved}
          handleModal={() => {
            setModalSaved(false);
          }}
          maxWidth="sm"
          body={
            <ChangeStatusModal
              handleModal={() => {
                setModalSaved(false);
                setModal(false);
              }}
              changeStatus={() => {
                const blk = JSON.parse(localStorage.getItem('savedTest'));

                formik.setFieldValue('block_ball', blk);
                const mp = blk.map((obj) => obj.test_keys);
                const omr =
                  'select_test_' + formik.values.omr_type.replace('-', '_');
                localStorage.setItem(omr, JSON.stringify(mp));
                loadingData();

                setModalSaved(false);
                setModal(false);
              }}
              yes={t('yes')}
              cancel={t('cancel')}
              text={t('Saqlash bajarilsinmi ?')}
            />
          }
        />
        <ModalWindow
          title={t('Tanlangan testlar')}
          modal={modal}
          handleModal={() => {
            setModalSaved(true);
          }}
          fullScreen
          body={
            <SelectList
              tests={showTestList}
              examId={state}
              examName={formik.values.name}
              classId={formik.values.classes}
              locationId={formik.values.locations}
            />
          }
        />
        <Loading loading={loading} />
        <Stack spacing={2} sx={{ width: '100%' }}>
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={open}
            autoHideDuration={6000}
            onClose={handleClose}
          >
            <Alert onClose={handleClose} severity="info" sx={{ width: '100%' }}>
              {snackbarMessage}
            </Alert>
          </Snackbar>
        </Stack>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={6} md={6} sm={6}>
            <Typography
              my={1}
              variant="h2"
              color="textPrimary"
              gutterBottom
              mt={2}
            >
              {state ? t('edit_exam') : t('add_exam')}
            </Typography>
          </Grid>
          <Grid item xs={6} md={6} sm={6}>
            <div className={classes.topBtns}>
              <FormControlLabel
                value="start"
                control={
                  <IOSSwitch
                    sx={{ m: 1 }}
                    checked={status}
                    value={status}
                    onChange={handleStatus}
                  />
                }
                label={t('status')}
                labelPlacement="start"
              />
            </div>
          </Grid>
        </Grid>

        <Grid item md={12} xs={12}>
          <form autoComplete="off" onSubmit={formik.handleSubmit}>
            <Grid container>
              <Grid item xs={12} md={12} sm={12} my={1}>
                <FormControl
                  size="small"
                  sx={{ width: '100%', backgroundColor: 'white' }}
                >
                  <InputLabel id="locations">{t('location')}</InputLabel>
                  <Select
                    labelId="locations"
                    label={t('location')}
                    className={classes.input}
                    fullWidth
                    name="locations"
                    value={formik.values.locations}
                    onBlur={formik.handleBlur}
                    onChange={(event) => {
                      formik.setFieldValue('locations', event.target.value);
                      const query = `status=1&locations=${event.target.value}`;
                      getByLocationId(query);
                    }}
                  >
                    {locationSchema.map((item, index) => (
                      <MenuItem key={`${index + 1}`} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={12} xs={12} sm={12} my={1}>
                <TextField
                  size="small"
                  InputProps={{
                    style: {
                      backgroundColor: theme.palette.background.paper,
                    },
                  }}
                  InputLabelProps={{
                    style: { color: 'black' },
                  }}
                  name="name"
                  fullWidth
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  variant="outlined"
                  label={t('enter_exam_name')}
                  value={formik.values.name}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                />
              </Grid>
              <Grid item xs={12} md={12} sm={12}>
                <FormControl
                  size="small"
                  sx={{ width: '100%', backgroundColor: 'white' }}
                >
                  <InputLabel id="classes">{t('class')}</InputLabel>
                  <Select
                    labelId="classes"
                    label={t('class')}
                    className={classes.input}
                    fullWidth
                    name="classes"
                    value={formik.values.classes}
                    onBlur={formik.handleBlur}
                    onChange={(event) => {
                      formik.setFieldValue('classes', event.target.value);
                    }}
                  >
                    {classesSchema.map((item, index) => (
                      <MenuItem key={`${index + 1}`} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={12} sm={12} my={1}>
                <FormControl
                  size="small"
                  sx={{ width: '100%', backgroundColor: 'white' }}
                >
                  <InputLabel id="teacherId">{t('teacher')}</InputLabel>
                  <Select
                    labelId="teacherId"
                    label={t('teacher')}
                    className={classes.input}
                    fullWidth
                    name="employee"
                    value={formik.values.employee}
                    onBlur={formik.handleBlur}
                    onChange={(event) => {
                      formik.setFieldValue('employee', event.target.value);
                    }}
                  >
                    {employeeSchema.map((item, index) => (
                      <MenuItem key={`${index + 1}`} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={12} sm={12} my={1}>
                <FormControl
                  size="small"
                  sx={{ width: '100%', backgroundColor: 'white' }}
                >
                  <InputLabel id="omr_typeId">{t('omr_type')}</InputLabel>
                  <Select
                    labelId="omr_typeId"
                    label={t('omr_type')}
                    className={classes.input}
                    fullWidth
                    name="omr_type"
                    value={formik.values.omr_type}
                    onBlur={formik.handleBlur}
                    onChange={(event) => {
                      formik.setFieldValue('omr_type', event.target.value);

                      if (event.target.value === '30-1') {
                        const item = [
                          {
                            test_keys: { tests: [], max_test: 30 },
                            block_name: scienceList[0],
                            ball: 1,
                          },
                        ];
                        formik.setFieldValue('block_ball', item);
                        const testItem = [
                          {
                            max_test: 30,
                            tests: [],
                          },
                        ];
                        localStorage.setItem(
                          'select_test_30_1',
                          JSON.stringify(testItem)
                        );
                        setSelectTestList([]);
                      } else if (event.target.value === '30-3') {
                        const item = [
                          {
                            test_keys: { tests: [], max_test: 10 },
                            block_name: scienceList[0],
                            ball: 1,
                          },
                          {
                            test_keys: { tests: [], max_test: 10 },
                            block_name: scienceList[0],
                            ball: 1,
                          },
                          {
                            test_keys: { tests: [], max_test: 10 },
                            block_name: scienceList[0],
                            ball: 1,
                          },
                        ];
                        formik.setFieldValue('block_ball', item);
                        const testItem = [
                          {
                            max_test: 10,
                            tests: [],
                          },
                          {
                            max_test: 10,
                            tests: [],
                          },
                          {
                            max_test: 10,
                            tests: [],
                          },
                        ];
                        localStorage.setItem(
                          'select_test_30_3',
                          JSON.stringify(testItem)
                        );
                        setSelectTestList([]);
                      } else if (event.target.value === '90') {
                        const item = [
                          {
                            test_keys: { tests: [], max_test: 10 },
                            block_name: scienceList[0],
                            ball: 1,
                          },
                          {
                            test_keys: { tests: [], max_test: 10 },
                            block_name: scienceList[0],
                            ball: 1,
                          },
                          {
                            test_keys: { tests: [], max_test: 10 },
                            block_name: scienceList[0],
                            ball: 1,
                          },
                          {
                            test_keys: { tests: [], max_test: 30 },
                            block_name: scienceList[0],
                            ball: 1,
                          },
                          {
                            test_keys: { tests: [], max_test: 30 },
                            block_name: scienceList[0],
                            ball: 1,
                          },
                        ];
                        formik.setFieldValue('block_ball', item);
                        const testItem = [
                          {
                            max_test: 10,
                            tests: [],
                          },
                          {
                            max_test: 10,
                            tests: [],
                          },
                          {
                            max_test: 10,
                            tests: [],
                          },
                          {
                            max_test: 30,
                            tests: [],
                          },
                          {
                            max_test: 30,
                            tests: [],
                          },
                        ];
                        localStorage.setItem(
                          'select_test_90',
                          JSON.stringify(testItem)
                        );
                        setSelectTestList([]);
                      }
                    }}
                  >
                    {omrType.map((item, index) => (
                      <MenuItem key={`${index + 1}`} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            {formik.values.omr_type === '30-1' ? (
              <div key="90">
                <Grid key="90" container spacing={2} my={1}>
                  <Grid item md={12} xs={12}>
                    <Card sx={{ padding: 2, marginBottom: 2 }}>
                      <Grid key="90" container>
                        <Grid item md={6} sm={6}>
                          <Typography
                            my={1}
                            variant="h5"
                            align="left"
                            color={colors.grey[700]}
                          >
                            30-1
                          </Typography>
                        </Grid>
                        {formik.values.block_ball.map((items, i) => (
                          <Grid key={`31${i + 1}`} container spacing={2}>
                            <Grid item md={6} xs={12} sm={12}>
                              <Typography
                                my={1}
                                variant="h5"
                                align="left"
                                color={colors.grey[700]}
                              >
                                {i + 1 + '. ' + t('blok')}
                              </Typography>
                              <FormControl
                                size="small"
                                sx={{
                                  width: '100%',
                                  backgroundColor: 'white',
                                }}
                              >
                                <InputLabel id="scienceId">
                                  {t('science')}
                                </InputLabel>
                                <Select
                                  labelId="scienceId"
                                  label={t('science')}
                                  className={classes.input}
                                  fullWidth
                                  name={`name_${i}`}
                                  value={items.block_name.science_id}
                                  onChange={(event) => {
                                    const arr = scienceList.filter(
                                      (value) =>
                                        value.science_id === event.target.value
                                    )[0];
                                    formik.values.block_ball[i].block_name =
                                      arr;
                                    formik.values.block_ball[i].test_keys = {
                                      tests: [],
                                      max_test: 30,
                                    };

                                    const testItem = [
                                      {
                                        max_test: 30,
                                        tests: [],
                                      },
                                    ];
                                    localStorage.setItem(
                                      'select_test_30_1',
                                      JSON.stringify(testItem)
                                    );

                                    formik.setFieldValue(
                                      'block_ball',
                                      formik.values.block_ball
                                    );
                                  }}
                                >
                                  {scienceList.map((item, index) => (
                                    <MenuItem
                                      key={`${index + 1}`}
                                      value={item.science_id}
                                    >
                                      {t(item.name.uz)}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </Grid>
                            <Grid item md={6} xs={12} sm={12}>
                              <Typography
                                my={1}
                                variant="h5"
                                align="left"
                                color={colors.grey[700]}
                              >
                                {i + 1 + '. ' + t('ball')}
                              </Typography>
                              <TextField
                                size="small"
                                type="number"
                                InputProps={{
                                  style: {
                                    backgroundColor:
                                      theme.palette.background.paper,
                                  },
                                }}
                                InputLabelProps={{
                                  style: { color: 'black' },
                                }}
                                name="ball"
                                fullWidth
                                onBlur={formik.handleBlur}
                                onChange={(event) => {
                                  console.log(event.target);
                                  formik.values.block_ball[i].ball =
                                    event.target.value;

                                  const testItem = [
                                    {
                                      max_test: 10,
                                      tests: [],
                                    },
                                    {
                                      max_test: 10,
                                      tests: [],
                                    },
                                    {
                                      max_test: 10,
                                      tests: [],
                                    },
                                  ];
                                  localStorage.setItem(
                                    'select_test_30_3',
                                    JSON.stringify(testItem)
                                  );
                                  formik.setFieldValue(
                                    'block_ball',
                                    formik.values.block_ball
                                  );
                                }}
                                variant="outlined"
                                label={t('enter_ball')}
                                value={items.ball}
                              />
                            </Grid>
                            <Divider />
                          </Grid>
                        ))}
                      </Grid>
                    </Card>
                  </Grid>
                </Grid>
              </div>
            ) : null}
            {formik.values.omr_type === '30-3' ? (
              <div key="90">
                <Grid key="90" container spacing={2} my={1}>
                  <Grid item md={12} xs={12}>
                    <Card sx={{ padding: 2, marginBottom: 2 }}>
                      <Grid key="90" container>
                        <Grid item md={6} sm={6}>
                          <Typography
                            my={1}
                            variant="h5"
                            align="left"
                            color={colors.grey[700]}
                          >
                            30-3
                          </Typography>
                        </Grid>
                        {formik.values.block_ball.map((items, i) => (
                          <Grid key={`33${i + 1}`} container spacing={2}>
                            <Grid item md={6} xs={12} sm={12}>
                              <Typography
                                my={1}
                                variant="h5"
                                align="left"
                                color={colors.grey[700]}
                              >
                                {i + 1 + '. ' + t('blok')}
                              </Typography>
                              <FormControl
                                size="small"
                                sx={{
                                  width: '100%',
                                  backgroundColor: 'white',
                                }}
                              >
                                <InputLabel id="scienceId">
                                  {t('science')}
                                </InputLabel>
                                <Select
                                  labelId="scienceId"
                                  label={t('science')}
                                  className={classes.input}
                                  fullWidth
                                  name={`name_${i}`}
                                  value={items.block_name.science_id}
                                  onChange={(event) => {
                                    const arr = scienceList.filter(
                                      (value) =>
                                        value.science_id === event.target.value
                                    )[0];
                                    formik.values.block_ball[i].block_name =
                                      arr;
                                    formik.values.block_ball[i].test_keys = {
                                      tests: [],
                                      max_test: 10,
                                    };

                                    const testItem = [
                                      {
                                        max_test: 10,
                                        tests: [],
                                      },
                                      {
                                        max_test: 10,
                                        tests: [],
                                      },
                                      {
                                        max_test: 10,
                                        tests: [],
                                      },
                                      {
                                        max_test: 30,
                                        tests: [],
                                      },
                                      {
                                        max_test: 30,
                                        tests: [],
                                      },
                                    ];
                                    localStorage.setItem(
                                      'select_test_90',
                                      JSON.stringify(testItem)
                                    );

                                    formik.setFieldValue(
                                      'block_ball',
                                      formik.values.block_ball
                                    );
                                  }}
                                >
                                  {scienceList.map((item, index) => (
                                    <MenuItem
                                      key={`${index + 1}`}
                                      value={item.science_id}
                                    >
                                      {t(item.name.uz)}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </Grid>
                            <Grid item md={6} xs={12} sm={12}>
                              <Typography
                                my={1}
                                variant="h5"
                                align="left"
                                color={colors.grey[700]}
                              >
                                {i + 1 + '. ' + t('ball')}
                              </Typography>
                              <TextField
                                size="small"
                                type="number"
                                InputProps={{
                                  style: {
                                    backgroundColor:
                                      theme.palette.background.paper,
                                  },
                                }}
                                InputLabelProps={{
                                  style: { color: 'black' },
                                }}
                                name="ball"
                                fullWidth
                                onBlur={formik.handleBlur}
                                onChange={(event) => {
                                  console.log(event.target);
                                  formik.values.block_ball[i].ball =
                                    event.target.value;
                                  formik.setFieldValue(
                                    'block_ball',
                                    formik.values.block_ball
                                  );
                                }}
                                variant="outlined"
                                label={t('enter_ball')}
                                value={items.ball}
                              />
                            </Grid>
                            <Divider />
                          </Grid>
                        ))}
                      </Grid>
                    </Card>
                  </Grid>
                </Grid>
              </div>
            ) : null}
            {formik.values.omr_type === '90' ? (
              <div key="90">
                <Grid key="90" container spacing={2} my={1}>
                  <Grid item md={12} xs={12}>
                    <Card sx={{ padding: 2, marginBottom: 2 }}>
                      <Grid key="90" container>
                        <Grid item md={6} sm={6}>
                          <Typography
                            my={1}
                            variant="h5"
                            align="left"
                            color={colors.grey[700]}
                          >
                            90
                          </Typography>
                        </Grid>
                        {formik.values.block_ball.map((items, i) => (
                          <Grid key={`90${i + 1}`} container spacing={2}>
                            <Grid item md={6} xs={12} sm={12}>
                              <Typography
                                my={1}
                                variant="h5"
                                align="left"
                                color={colors.grey[700]}
                              >
                                {i + 1 + '. ' + t('blok')}
                              </Typography>
                              <FormControl
                                size="small"
                                sx={{
                                  width: '100%',
                                  backgroundColor: 'white',
                                }}
                              >
                                <InputLabel id="scienceId">
                                  {t('science')}
                                </InputLabel>
                                <Select
                                  labelId="scienceId"
                                  label={t('science')}
                                  className={classes.input}
                                  fullWidth
                                  name={`name_${i}`}
                                  value={items.block_name.science_id}
                                  onChange={(event) => {
                                    console.log(event.target);
                                    const arr = scienceList.filter(
                                      (value) =>
                                        value.science_id === event.target.value
                                    )[0];
                                    formik.values.block_ball[i].block_name =
                                      arr;
                                    if (i === 0 || i === 1 || i === 2) {
                                      formik.values.block_ball[i].test_keys = {
                                        tests: [],
                                        max_test: 10,
                                      };
                                    } else {
                                      formik.values.block_ball[i].test_keys = {
                                        tests: [],
                                        max_test: 30,
                                      };
                                    }

                                    formik.setFieldValue(
                                      'block_ball',
                                      formik.values.block_ball
                                    );
                                  }}
                                >
                                  {scienceList.map((item, index) => (
                                    <MenuItem
                                      key={`${index + 1}`}
                                      value={item.science_id}
                                    >
                                      {t(item.name.uz)}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </Grid>
                            <Grid item md={6} xs={12} sm={12}>
                              <Typography
                                my={1}
                                variant="h5"
                                align="left"
                                color={colors.grey[700]}
                              >
                                {i + 1 + '. ' + t('ball')}
                              </Typography>
                              <TextField
                                size="small"
                                type="number"
                                InputProps={{
                                  style: {
                                    backgroundColor:
                                      theme.palette.background.paper,
                                  },
                                }}
                                InputLabelProps={{
                                  style: { color: 'black' },
                                }}
                                name="ball"
                                fullWidth
                                onBlur={formik.handleBlur}
                                onChange={(event) => {
                                  console.log(event.target);
                                  formik.values.block_ball[i].ball =
                                    event.target.value;
                                  formik.setFieldValue(
                                    'block_ball',
                                    formik.values.block_ball
                                  );
                                }}
                                variant="outlined"
                                label={t('enter_ball')}
                                value={items.ball}
                              />
                            </Grid>
                            <Divider />
                          </Grid>
                        ))}
                      </Grid>
                    </Card>
                  </Grid>
                </Grid>
              </div>
            ) : null}
            <Grid container spacing={1}>
              {formik.values.block_ball.length > 0 ? (
                <Grid item xs={12} md={12} sm={12}>
                  <Button
                    sx={{
                      backgroundColor: '#2A455F',
                      color: colors.white,
                      textTransform: 'none',
                    }}
                    name="add"
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      setLoading(true);
                      const mainArr = [];
                      mainArr.blok = formik.values.block_ball;
                      mainArr.tests = [];

                      const arr1 = mainArr.blok.map((obj) => obj.test_keys);
                      const arr2 = arr1.map((obj) => obj.tests);
                      const arr3 = arr2.map((obj) =>
                        obj.map((obj1) => obj1.testId)
                      );
                      setSelectTestList3(arr3);

                      getTestShow(mainArr, arr3, 0);
                    }}
                  >
                    Ko`rish
                  </Button>
                </Grid>
              ) : null}
              {formik.values.block_ball.map((items, index) => (
                <Grid key={`${index + 1}`} item xs={12} md={12} sm={12} my={1}>
                  <Accordion
                    expanded={expanded === `panel${index + 1}`}
                    onChange={handleChangeExpanded(
                      `panel${index + 1}`,
                      items,
                      index
                    )}
                  >
                    <AccordionSummary
                      aria-controls="panel1d-content"
                      id="panel1d-header"
                    >
                      <Grid container>
                        <Grid item xs={11} md={11} sm={11}>
                          <Typography>
                            Blok #{index + 1} ({items.block_name.name.uz})
                            &nbsp; &nbsp; Ball:
                            {items.ball} &nbsp; &nbsp; Tanlangan testlar soni:{' '}
                            {items.test_keys.tests.length}
                          </Typography>
                        </Grid>
                      </Grid>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid container my={1}>
                        <Grid item xs={12} md={12} sm={12} my={2}>
                          <Autocomplete
                            fullWidth
                            size="small"
                            id="combo-box1"
                            freeSolo
                            clearOnBlur
                            className={classes.search}
                            value={formik.values.topic_name}
                            options={topicList}
                            onChange={(event, newValue) => {
                              if (newValue) {
                                formik.setFieldValue(
                                  'topic_name',
                                  newValue.name.uz
                                );

                                getTestsByTopicId(1, newValue.topic_test_id);
                              }
                            }}
                            getOptionLabel={(option) => {
                              // Value selected with enter, right from the input
                              if (typeof option === 'string') {
                                return option;
                              }

                              // Regular option
                              return option.name.uz;
                            }}
                            renderOption={(props, option) => (
                              <li {...props}>{option.name.uz}</li>
                            )}
                            renderInput={(params) => (
                              <TextField {...params} label={t('topic')} />
                            )}
                          />
                        </Grid>
                        {testList.map((item, i) => (
                          <Grid key={item.test_id} item xs={12} md={6} sm={12}>
                            <Card sx={{ marginRight: 1, marginBottom: 1 }}>
                              <Tooltip
                                title={
                                  selectTestList.filter(
                                    (value) => value.testId === item.test_id
                                  ).length < 0
                                    ? t('selection')
                                    : t('selected')
                                }
                                placement="top"
                                arrow
                              >
                                <IconButton
                                  variant="filled"
                                  onClick={() => {
                                    selectTest(
                                      item,
                                      selectTestList.filter(
                                        (value) => value.testId === item.test_id
                                      ).length <= 0
                                        ? 1
                                        : 0,
                                      index
                                    );
                                  }}
                                >
                                  {!selectTestList.some(
                                    (value) => value.testId === item.test_id
                                  ) ? (
                                    <CheckCircleIcon color="success" />
                                  ) : (
                                    <HighlightOffIcon color="error" />
                                  )}
                                </IconButton>
                              </Tooltip>
                              <CardContent>
                                <div
                                  // eslint-disable-next-line react/no-danger
                                  dangerouslySetInnerHTML={{
                                    __html: item.question.replace(
                                      'numberNN',
                                      (page - 1) * pageSize + i + 1 + '. '
                                    ),
                                  }}
                                />
                                <Divider size="medium" color="primary" />
                                <div>
                                  <Button
                                    fullWidth
                                    disableRipple
                                    sx={{
                                      marginTop: 1,
                                      marginBottom: 1,
                                      textTransform: 'none',
                                      justifyContent: 'flex-start',
                                      paddingRight: 1,
                                    }}
                                    variant={
                                      selectTestList.some(
                                        (value) =>
                                          value.testId === item.test_id &&
                                          value.key === 1
                                      )
                                        ? 'contained'
                                        : 'outlined'
                                    }
                                    color={
                                      selectTestList.some(
                                        (value) =>
                                          value.testId === item.test_id &&
                                          value.key === 1
                                      )
                                        ? 'primary'
                                        : 'warning'
                                    }
                                    startIcon={<Avatar>A</Avatar>}
                                    onClick={() => {
                                      selectOption(
                                        item,
                                        selectTestList.filter(
                                          (value) =>
                                            value.testId === item.test_id
                                        ).length <= 0
                                          ? 1
                                          : 0,
                                        items.scienceId,
                                        1,
                                        index
                                      );
                                    }}
                                  >
                                    <div
                                      style={{ paddingTop: 10 }}
                                      // eslint-disable-next-line react/no-danger
                                      dangerouslySetInnerHTML={{
                                        __html: item.option_a.replace(
                                          'optionA',
                                          ''
                                        ),
                                      }}
                                    />
                                  </Button>
                                </div>

                                <Divider size="medium" color="primary" />
                                <div>
                                  <Button
                                    fullWidth
                                    disableRipple
                                    sx={{
                                      marginTop: 1,
                                      marginBottom: 1,
                                      textTransform: 'none',
                                      justifyContent: 'flex-start',
                                    }}
                                    variant={
                                      selectTestList.some(
                                        (value) =>
                                          value.testId === item.test_id &&
                                          value.key === 2
                                      )
                                        ? 'contained'
                                        : 'outlined'
                                    }
                                    color={
                                      selectTestList.some(
                                        (value) =>
                                          value.testId === item.test_id &&
                                          value.key === 2
                                      )
                                        ? 'primary'
                                        : 'warning'
                                    }
                                    startIcon={<Avatar>B</Avatar>}
                                    onClick={() => {
                                      selectOption(
                                        item,
                                        selectTestList.filter(
                                          (value) =>
                                            value.testId === item.test_id
                                        ).length <= 0
                                          ? 1
                                          : 0,
                                        items.scienceId,
                                        2,
                                        index
                                      );
                                    }}
                                  >
                                    <div
                                      style={{ paddingTop: 10 }}
                                      // eslint-disable-next-line react/no-danger
                                      dangerouslySetInnerHTML={{
                                        __html: item.option_b.replace(
                                          'optionB',
                                          ''
                                        ),
                                      }}
                                    />
                                  </Button>
                                </div>
                                <Divider size="medium" color="primary" />
                                <div>
                                  <Button
                                    fullWidth
                                    disableRipple
                                    sx={{
                                      marginTop: 1,
                                      marginBottom: 1,
                                      textTransform: 'none',
                                      justifyContent: 'flex-start',
                                    }}
                                    variant={
                                      selectTestList.some(
                                        (value) =>
                                          value.testId === item.test_id &&
                                          value.key === 3
                                      )
                                        ? 'contained'
                                        : 'outlined'
                                    }
                                    color={
                                      selectTestList.some(
                                        (value) =>
                                          value.testId === item.test_id &&
                                          value.key === 3
                                      )
                                        ? 'primary'
                                        : 'warning'
                                    }
                                    startIcon={<Avatar>C</Avatar>}
                                    onClick={() => {
                                      selectOption(
                                        item,
                                        selectTestList.filter(
                                          (value) =>
                                            value.testId === item.test_id
                                        ).length <= 0
                                          ? 1
                                          : 0,
                                        items.scienceId,
                                        3,
                                        index
                                      );
                                    }}
                                  >
                                    <div
                                      style={{ paddingTop: 10 }}
                                      // eslint-disable-next-line react/no-danger
                                      dangerouslySetInnerHTML={{
                                        __html: item.option_c.replace(
                                          'optionC',
                                          ''
                                        ),
                                      }}
                                    />
                                  </Button>
                                </div>
                                <Divider size="medium" color="primary" />
                                <div>
                                  <Button
                                    fullWidth
                                    disableRipple
                                    sx={{
                                      marginTop: 1,
                                      marginBottom: 1,
                                      textTransform: 'none',
                                      justifyContent: 'flex-start',
                                    }}
                                    variant={
                                      selectTestList.some(
                                        (value) =>
                                          value.testId === item.test_id &&
                                          value.key === 4
                                      )
                                        ? 'contained'
                                        : 'outlined'
                                    }
                                    color={
                                      selectTestList.some(
                                        (value) =>
                                          value.testId === item.test_id &&
                                          value.key === 4
                                      )
                                        ? 'primary'
                                        : 'warning'
                                    }
                                    startIcon={<Avatar>D</Avatar>}
                                    onClick={() => {
                                      selectOption(
                                        item,
                                        selectTestList.filter(
                                          (value) =>
                                            value.testId === item.test_id
                                        ).length < 0
                                          ? 1
                                          : 0,
                                        items.scienceId,
                                        4,
                                        index
                                      );
                                    }}
                                  >
                                    <div
                                      style={{ paddingTop: 10 }}
                                      // eslint-disable-next-line react/no-danger
                                      dangerouslySetInnerHTML={{
                                        __html: item.option_d.replace(
                                          'optionD',
                                          ''
                                        ),
                                      }}
                                    />
                                  </Button>
                                </div>
                              </CardContent>
                            </Card>
                          </Grid>
                        ))}
                      </Grid>
                      {testList.length === 0 ? (
                        <Typography
                          my={1}
                          variant="h4"
                          align="left"
                          color={colors.grey[700]}
                        >
                          {t('no_test_data')}
                        </Typography>
                      ) : null}
                      {testList.length > 0 ? (
                        <Stack spacing={2}>
                          <Pagination
                            ullwidth="true"
                            count={countPages}
                            variant="outlined"
                            shape="rounded"
                            page={page}
                            onChange={handleChange}
                          />
                        </Stack>
                      ) : null}
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              ))}
              <br />

              {/* {blokList.length !== 0 ? (
                <Grid container my={1}>
                  <Grid item xs={11} md={11} sm={11}>
                    <Autocomplete
                      multiple
                      fullWidth
                      size="small"
                      id="combo-box1"
                      disableCloseOnSelect
                      className={classes.search}
                      options={blokList}
                      onChange={(event, newValue) => {
                        setDeleteBlokList(newValue);
                      }}
                      getOptionLabel={(option) => {
                        // Value selected with enter, right from the input
                        if (typeof option === 'string') {
                          return option;
                        }

                        // Regular option
                        return option.scienceName;
                      }}
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <Checkbox
                            key={option.user_id}
                            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                            checkedIcon={<CheckBoxIcon fontSize="small" />}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option.scienceName}
                        </li>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={t("Bloklarni o'chirish")}
                          InputProps={{
                            style: {
                              backgroundColor: theme.palette.background.paper,
                            },
                            ...params.InputProps,
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={1} md={1} sm={1}>
                    <IconButton
                      sx={{
                        color: '#2A455F',
                      }}
                      variant="contained"
                      onClick={() => {
                        // handleDeleteModal();
                      }}
                      // color="info"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              ) : null} */}
            </Grid>
            <Grid item xl={12} md={12} xs={12} my={1}>
              <TextField
                size="small"
                InputProps={{
                  style: {
                    backgroundColor: theme.palette.background.paper,
                  },
                }}
                InputLabelProps={{
                  style: { color: 'black' },
                }}
                name="notes"
                fullWidth
                multiline
                rows={4}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                variant="outlined"
                label={t('description')}
                value={formik.values.notes}
                // error={formik.touched.notes && Boolean(formik.errors.notes)}
              />
            </Grid>

            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                gap: 2,
                py: 3,
              }}
            >
              <Button
                type="submit"
                sx={{
                  backgroundColor: '#CFE0F0',
                  color: '#161616',
                  textTransform: 'none',
                }}
                variant="contained"
                onClick={() => navigate('/app/exam')}
              >
                {t('cancel')}
              </Button>
              <Button
                sx={{
                  backgroundColor: '#2A455F',
                  color: colors.white,
                  textTransform: 'none',
                }}
                name="add"
                variant="contained"
                type="submit"
                color="primary"
                onClick={() => console.log()}
                startIcon={
                  formik.isSubmitting ? (
                    <CircularProgress size="0.9rem" />
                  ) : undefined
                }
              >
                {renderButton(state, formik.isSubmitting)}
              </Button>
            </Box>
          </form>
        </Grid>
        <ToastContainer />
      </Container>
    </Box>
  );
};
